import Vue from "vue";

// access token
import { getAccessToken } from "@/helpers/auth";

let config = {};

config.baseURL = process.env.VUE_APP_API;

// axios
import axios from "axios";

const axiosIns = axios.create(config);

axiosIns.interceptors.request.use(function (config) {
    config.headers.Authorization =  getAccessToken();

    return config;
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
