export default {
    namespace: true,
    state: {
        widths: [],
        heights: [],
        ratio: undefined,
        currentScaleIndex: 0,
        currentWidth: undefined,
        currentHeight: undefined,
        scales: ["1", "1.5", "2", "2.5", "3"],
    },
    getters: {
        getRatio: ({ ratio }) => ratio,
        getScales: ({ scales }) => scales,
        getWidths: ({ widths }) => widths,
        getHeights: ({ heights }) => heights,
        getCurrentWidth: ({ currentWidth }) => currentWidth,
        getCurrentHeight: ({ currentHeight }) => currentHeight,
        getCurrentScaleIndex: ({ currentScaleIndex }) => currentScaleIndex,
        getCurrentScale: ({ scales, currentScaleIndex }) => scales[currentScaleIndex],
    },
    mutations: {
        setRatio: (state, ratio) => state.ratio = ratio,
        setWidth: (state, width) => state.width = width,
        setWidths: (state, widths) => state.widths = widths,
        setScales: (state, scales) => state.scales = scales,
        setHeight: (state, height) => state.height = height,
        addScale: (state, scale) => state.scales.push(scale),
        setHeights: (state, heights) => state.heights = heights,
        setCurrentWidth: (state, width) => state.currentWidth = width,
        setCurrentHeight: (state, height) => state.currentHeight = height,
        setCurrentScaleIndex: (state, scale) => state.currentScaleIndex = scale,
    },
};
