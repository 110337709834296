import _ from "lodash";
import { convertMinutesToHoursAndMinutes } from "@/helpers";
import { getUnassignedQueues, getQueuesStats, getAssignedQueues } from "@/api/queues";

export default {
    namespace: true,
    state: {
        stats: undefined,
        assignedQueues: undefined,
        unassignedQueues: undefined,
    },
    getters: {
        getQueueStats: ({ stats }) => stats,
        getAssignedQueues: ({ assignedQueues }) => assignedQueues,
        getUnassignedQueues: ({ unassignedQueues }) => unassignedQueues,
    },
    mutations: {
        SET_QUEUE_STATS(state, payload) {
            const stats = {
                services_count: payload.totalService,
                operators_count: payload.totalOnlineOperator,
                time_estimation: convertMinutesToHoursAndMinutes(payload.totalTime),
            };

            state.stats = stats;
        },
        SET_ASSIGNED_QUEUES(state, payload) {
            state.assignedQueues = payload;
        },
        SET_UNASSIGNED_QUEUES(state, payload) {
            state.unassignedQueues = payload;
        },
    },
    actions: {
        async fetchQueueStats({ commit }) {
            let response;

            try {
                response = getQueuesStats();
            } catch (error) {
                throw new Error(error);
            }

            response
                .then(({ data }) => {
                    commit("SET_QUEUE_STATS", data.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            return response;
        },
        async fetchUnassignedQueues({ commit }) {
            let response;

            try {
                response = getUnassignedQueues();
            } catch (error) {
                throw new Error(error);
            }

            response
                .then(({ data }) => {
                    commit("SET_UNASSIGNED_QUEUES", data.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            return response;
        },
        async fetchAssignedQueues({ commit }) {
            let response;
          
            try {
                response = getAssignedQueues();
            } catch (error) {
                throw new Error(error);
            }
          
            response
                .then(({ data }) => {
                    commit("SET_ASSIGNED_QUEUES", data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
          
            return response;
          },
    },
};
