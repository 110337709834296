export default [
    {
        path: "/revisions",
        name: "revisions",
        component: () => import("@views/revisions/Index.vue"),
        meta: {
            layout: "expanded",
            identifier: "revisions",
            header: {
                title: "Revision List",
                subtitle: "Manage Your Revision List",
            },
        },
    },
    {
        path: "/revisions/preview",
        name: "revisions-preview",
        component: () => import("@views/revisions/Preview.vue"),
        meta: {
            layout: "collapsed",
            identifier: "revisions",
            header: {
                title: "Revision List",
                subtitle: "Manage Your Revision List",
            },
        },
    },
    {
        path: "/revisions/preview/item-removal",
        name: "revisions-preview-item-removal",
        component: () => import("@views/revisions/ItemRemoval.vue"),
        meta: {
            layout: "collapsed",
            identifier: "revisions",
            header: {
                title: "Revision List",
                subtitle: "Manage Your Revision List",
            },
        },
    },
    {
        path: "/revisions/preview/image-enhancement",
        name: "revisions-preview-image-enhancement",
        component: () => import("@views/revisions/ImageEnhancement.vue"),
        meta: {
            layout: "collapsed",
            identifier: "revisions",
            header: {
                title: "Revision List",
                subtitle: "Manage Your Revision List",
            },
        },
    },
    {
        path: "/revisions/preview/virtual-staging",
        name: "revisions-preview-virtual-staging",
        component: () => import("@views/revisions/VirtualStaging.vue"),
        meta: {
            layout: "collapsed",
            identifier: "revisions",
            header: {
                title: "Revision List",
                subtitle: "Manage Your Revision List",
            },
        },
    },
];
