import _ from "lodash";
import countries from "@/data/countries";

export default {
    namespace: true,
    state: {
        countries: countries,
    },
    getters: {
        getCountries: ({ countries }) => countries,
    },
    mutations: {
        searchInCountries(state, payload) {
            const regex = new RegExp(payload, "gi");
            const data = countries.filter((country) => {
                if (country.name.search(regex) > -1) {
                    return country;
                }
            });

            state.countries = data;
        },
    },
    actions: {},
};
