export default [
    {
        name: "Afghanistan",
        code: "af",
        flag: require("@assets/svg/flags/af.svg"),
        dial_code: "+93",
        id: 0,
        continent: "Asia",
    },
    {
        name: "Aland Islands",
        code: "ax",
        flag: require("@assets/svg/flags/ax.svg"),
        dial_code: "+358-18",
        id: 1,
        continent: "Europe",
    },
    {
        name: "Albania",
        code: "al",
        flag: require("@assets/svg/flags/al.svg"),
        dial_code: "+355",
        id: 2,
        continent: "Europe",
    },
    {
        name: "Algeria",
        code: "dz",
        flag: require("@assets/svg/flags/dz.svg"),
        dial_code: "+213",
        id: 3,
        continent: "Africa",
    },
    {
        name: "American Samoa",
        code: "as",
        flag: require("@assets/svg/flags/as.svg"),
        dial_code: "+1-684",
        id: 4,
        continent: "Oceania",
    },
    {
        name: "Andorra",
        code: "ad",
        flag: require("@assets/svg/flags/ad.svg"),
        dial_code: "+376",
        id: 5,
        continent: "Europe",
    },
    {
        name: "Angola",
        code: "ao",
        flag: require("@assets/svg/flags/ao.svg"),
        dial_code: "+244",
        id: 6,
        continent: "Africa",
    },
    {
        name: "Anguilla",
        code: "ai",
        flag: require("@assets/svg/flags/ai.svg"),
        dial_code: "+1264",
        id: 7,
        continent: "North America",
    },
    {
        name: "Antarctica",
        code: "aq",
        flag: require("@assets/svg/flags/aq.svg"),
        dial_code: "+672",
        id: 8,
    },
    {
        name: "Antigua and Barbuda",
        code: "ag",
        flag: require("@assets/svg/flags/ag.svg"),
        dial_code: "+1-268",
        id: 9,
        continent: "North America",
    },
    {
        name: "Argentina",
        code: "ar",
        flag: require("@assets/svg/flags/ar.svg"),
        dial_code: "+54",
        id: 10,
        continent: "South America",
    },
    {
        name: "Armenia",
        code: "am",
        flag: require("@assets/svg/flags/am.svg"),
        dial_code: "+374",
        id: 11,
        continent: "Asia",
    },
    {
        name: "Aruba",
        code: "aw",
        flag: require("@assets/svg/flags/aw.svg"),
        dial_code: "+297",
        id: 12,
        continent: "South America",
    },
    {
        name: "Australia",
        code: "au",
        flag: require("@assets/svg/flags/au.svg"),
        dial_code: "+61",
        id: 13,
        continent: "Oceania",
    },
    {
        name: "Austria",
        code: "at",
        flag: require("@assets/svg/flags/at.svg"),
        dial_code: "+43",
        id: 14,
        continent: "Europe",
    },
    {
        name: "Azerbaijan",
        code: "az",
        flag: require("@assets/svg/flags/az.svg"),
        dial_code: "+994",
        id: 15,
        continent: "Asia",
    },
    {
        name: "Bahamas",
        code: "bs",
        flag: require("@assets/svg/flags/bs.svg"),
        dial_code: "+1-242",
        id: 16,
        continent: "North America",
    },
    {
        name: "Bahrain",
        code: "bh",
        flag: require("@assets/svg/flags/bh.svg"),
        dial_code: "+973",
        id: 17,
        continent: "Asia",
    },
    {
        name: "Bangladesh",
        code: "bd",
        flag: require("@assets/svg/flags/bd.svg"),
        dial_code: "+880",
        id: 18,
        continent: "Asia",
    },
    {
        name: "Barbados",
        code: "bb",
        flag: require("@assets/svg/flags/bb.svg"),
        dial_code: "+1-246",
        id: 19,
        continent: "North America",
    },
    {
        name: "Belarus",
        code: "by",
        flag: require("@assets/svg/flags/by.svg"),
        dial_code: "+375",
        id: 20,
        continent: "Europe",
    },
    {
        name: "Belgium",
        code: "be",
        flag: require("@assets/svg/flags/be.svg"),
        dial_code: "+32",
        id: 21,
        continent: "Europe",
    },
    {
        name: "Belize",
        code: "bz",
        flag: require("@assets/svg/flags/bz.svg"),
        dial_code: "+501",
        id: 22,
        continent: "North America",
    },
    {
        name: "Benin",
        code: "bj",
        flag: require("@assets/svg/flags/bj.svg"),
        dial_code: "+229",
        id: 23,
        continent: "Africa",
    },
    {
        name: "Bermuda",
        code: "bm",
        flag: require("@assets/svg/flags/bm.svg"),
        dial_code: "+1-441",
        id: 24,
        continent: "North America",
    },
    {
        name: "Bhutan",
        code: "bt",
        flag: require("@assets/svg/flags/bt.svg"),
        dial_code: "+975",
        id: 25,
        continent: "Asia",
    },
    {
        name: "Bolivia",
        code: "bo",
        flag: require("@assets/svg/flags/bo.svg"),
        dial_code: "+591",
        id: 26,
        continent: "South America",
    },
    {
        name: "Bonaire, Sint Eustatius and Saba",
        code: "bq",
        flag: require("@assets/svg/flags/bq.svg"),
        dial_code: "+599",
        id: 27,
        continent: "South America",
    },
    {
        name: "Bosnia and Herzegovina",
        code: "ba",
        flag: require("@assets/svg/flags/ba.svg"),
        dial_code: "+387",
        id: 28,
        continent: "Europe",
    },
    {
        name: "Botswana",
        code: "bw",
        flag: require("@assets/svg/flags/bw.svg"),
        dial_code: "+267",
        id: 29,
        continent: "Africa",
    },
    {
        name: "Bouvet Island",
        code: "bv",
        flag: require("@assets/svg/flags/bv.svg"),
        dial_code: "+47",
        id: 30,
    },
    {
        name: "Brazil",
        code: "br",
        flag: require("@assets/svg/flags/br.svg"),
        dial_code: "+55",
        id: 31,
        continent: "South America",
    },
    {
        name: "British Indian Ocean Territory",
        code: "io",
        flag: require("@assets/svg/flags/io.svg"),
        dial_code: "+246",
        id: 32,
        continent: "Asia",
    },
    {
        name: "Virgin Islands (British)",
        code: "vg",
        flag: require("@assets/svg/flags/vg.svg"),
        dial_code: "+1-284",
        id: 33,
        continent: "North America",
    },
    {
        name: "Brunei Darussalam",
        code: "bn",
        flag: require("@assets/svg/flags/bn.svg"),
        dial_code: "+673",
        id: 34,
        continent: "Asia",
    },
    {
        name: "Bulgaria",
        code: "bg",
        flag: require("@assets/svg/flags/bg.svg"),
        dial_code: "+359",
        id: 35,
        continent: "Europe",
    },
    {
        name: "Burkina Faso",
        code: "bf",
        flag: require("@assets/svg/flags/bf.svg"),
        dial_code: "+226",
        id: 36,
        continent: "Africa",
    },
    {
        name: "Burundi",
        code: "bi",
        flag: require("@assets/svg/flags/bi.svg"),
        dial_code: "+257",
        id: 37,
        continent: "Africa",
    },
    {
        name: "Cambodia",
        code: "kh",
        flag: require("@assets/svg/flags/kh.svg"),
        dial_code: "+855",
        id: 38,
        continent: "Asia",
    },
    {
        name: "Cameroon",
        code: "cm",
        flag: require("@assets/svg/flags/cm.svg"),
        dial_code: "+237",
        id: 39,
        continent: "Africa",
    },
    {
        name: "Canada",
        code: "ca",
        flag: require("@assets/svg/flags/ca.svg"),
        dial_code: "+1",
        id: 40,
        continent: "North America",
    },
    {
        name: "Cabo Verde",
        code: "cv",
        flag: require("@assets/svg/flags/cv.svg"),
        dial_code: "+238",
        id: 41,
        continent: "Africa",
    },
    {
        name: "Cayman Islands",
        code: "ky",
        flag: require("@assets/svg/flags/ky.svg"),
        dial_code: "+1-345",
        id: 42,
        continent: "North America",
    },
    {
        name: "Central African Republic",
        code: "cf",
        flag: require("@assets/svg/flags/cf.svg"),
        dial_code: "+236",
        id: 43,
        continent: "Africa",
    },
    {
        name: "Chad",
        code: "td",
        flag: require("@assets/svg/flags/td.svg"),
        dial_code: "+235",
        id: 44,
        continent: "Africa",
    },
    {
        name: "Chile",
        code: "cl",
        flag: require("@assets/svg/flags/cl.svg"),
        dial_code: "+56",
        id: 45,
        continent: "South America",
    },
    {
        name: "China",
        code: "cn",
        flag: require("@assets/svg/flags/cn.svg"),
        dial_code: "+86",
        id: 46,
        continent: "Asia",
    },
    {
        name: "Christmas Island",
        code: "cx",
        flag: require("@assets/svg/flags/cx.svg"),
        dial_code: "+61",
        id: 47,
        continent: "Asia",
    },
    {
        name: "Cocos (Keeling) Islands",
        code: "cc",
        flag: require("@assets/svg/flags/cc.svg"),
        dial_code: "+61",
        id: 48,
        continent: "Asia",
    },
    {
        name: "Colombia",
        code: "co",
        flag: require("@assets/svg/flags/co.svg"),
        dial_code: "+57",
        id: 49,
        continent: "South America",
    },
    {
        name: "Comoros",
        code: "km",
        flag: require("@assets/svg/flags/km.svg"),
        dial_code: "+269",
        id: 50,
        continent: "Africa",
    },
    {
        name: "Cook Islands",
        code: "ck",
        flag: require("@assets/svg/flags/ck.svg"),
        dial_code: "+682",
        id: 51,
        continent: "Oceania",
    },
    {
        name: "Costa Rica",
        code: "cr",
        flag: require("@assets/svg/flags/cr.svg"),
        dial_code: "+506",
        id: 52,
        continent: "North America",
    },
    {
        name: "Croatia",
        code: "hr",
        flag: require("@assets/svg/flags/hr.svg"),
        dial_code: "+385",
        id: 53,
        continent: "Europe",
    },
    {
        name: "Cuba",
        code: "cu",
        flag: require("@assets/svg/flags/cu.svg"),
        dial_code: "+53",
        id: 54,
        continent: "North America",
    },
    {
        name: "Curaçao",
        code: "cw",
        flag: require("@assets/svg/flags/cw.svg"),
        dial_code: "+599",
        id: 55,
        continent: "South America",
    },
    {
        name: "Cyprus",
        code: "cy",
        flag: require("@assets/svg/flags/cy.svg"),
        dial_code: "+357",
        id: 56,
        continent: "Europe",
    },
    {
        name: "Czech Republic",
        code: "cz",
        flag: require("@assets/svg/flags/cz.svg"),
        dial_code: "+420",
        id: 57,
        continent: "Europe",
    },
    {
        name: "Democratic Republic of the Congo",
        code: "cd",
        flag: require("@assets/svg/flags/cd.svg"),
        dial_code: "+243",
        id: 58,
        continent: "Africa",
    },
    {
        name: "Denmark",
        code: "dk",
        flag: require("@assets/svg/flags/dk.svg"),
        dial_code: "+45",
        id: 59,
        continent: "Europe",
    },
    {
        name: "Djibouti",
        code: "dj",
        flag: require("@assets/svg/flags/dj.svg"),
        dial_code: "+253",
        id: 60,
        continent: "Africa",
    },
    {
        name: "Dominica",
        code: "dm",
        flag: require("@assets/svg/flags/dm.svg"),
        dial_code: "+1-767",
        id: 61,
        continent: "North America",
    },
    {
        name: "Dominican Republic",
        code: "do",
        flag: require("@assets/svg/flags/do.svg"),
        dial_code: "+1-809",
        id: 62,
        continent: "North America",
    },
    {
        name: "Timor-Leste",
        code: "tl",
        flag: require("@assets/svg/flags/tl.svg"),
        dial_code: "+670",
        id: 63,
        continent: "Asia",
    },
    {
        name: "Ecuador",
        code: "ec",
        flag: require("@assets/svg/flags/ec.svg"),
        dial_code: "+593",
        id: 64,
        continent: "South America",
    },
    {
        name: "Egypt",
        code: "eg",
        flag: require("@assets/svg/flags/eg.svg"),
        dial_code: "+20",
        id: 65,
        continent: "Africa",
    },
    {
        name: "El Salvador",
        code: "sv",
        flag: require("@assets/svg/flags/sv.svg"),
        dial_code: "+503",
        id: 66,
        continent: "North America",
    },
    {
        name: "Equatorial Guinea",
        code: "gq",
        flag: require("@assets/svg/flags/gq.svg"),
        dial_code: "+240",
        id: 67,
        continent: "Africa",
    },
    {
        name: "Eritrea",
        code: "er",
        flag: require("@assets/svg/flags/er.svg"),
        dial_code: "+291",
        id: 68,
        continent: "Africa",
    },
    {
        name: "Estonia",
        code: "ee",
        flag: require("@assets/svg/flags/ee.svg"),
        dial_code: "+372",
        id: 69,
        continent: "Europe",
    },
    {
        name: "Ethiopia",
        code: "et",
        flag: require("@assets/svg/flags/et.svg"),
        dial_code: "+251",
        id: 70,
        continent: "Africa",
    },
    {
        name: "Falkland Islands",
        code: "fk",
        flag: require("@assets/svg/flags/fk.svg"),
        dial_code: "+500",
        id: 71,
        continent: "South America",
    },
    {
        name: "Faroe Islands",
        code: "fo",
        flag: require("@assets/svg/flags/fo.svg"),
        dial_code: "+298",
        id: 72,
        continent: "Europe",
    },
    {
        name: "Fiji",
        code: "fj",
        flag: require("@assets/svg/flags/fj.svg"),
        dial_code: "+679",
        id: 73,
        continent: "Oceania",
    },
    {
        name: "Finland",
        code: "fi",
        flag: require("@assets/svg/flags/fi.svg"),
        dial_code: "+358",
        id: 74,
        continent: "Europe",
    },
    {
        name: "France",
        code: "fr",
        flag: require("@assets/svg/flags/fr.svg"),
        dial_code: "+33",
        id: 75,
        continent: "Europe",
    },
    {
        name: "French Guiana",
        code: "gf",
        flag: require("@assets/svg/flags/gf.svg"),
        dial_code: "+594",
        id: 76,
        continent: "South America",
    },
    {
        name: "French Polynesia",
        code: "pf",
        flag: require("@assets/svg/flags/pf.svg"),
        dial_code: "+689",
        id: 77,
        continent: "Oceania",
    },
    {
        name: "French Southern Territories",
        code: "tf",
        flag: require("@assets/svg/flags/tf.svg"),
        dial_code: "+262",
        id: 78,
        continent: "Africa",
    },
    {
        name: "Gabon",
        code: "ga",
        flag: require("@assets/svg/flags/ga.svg"),
        dial_code: "+241",
        id: 79,
        continent: "Africa",
    },
    {
        name: "Gambia",
        code: "gm",
        flag: require("@assets/svg/flags/gm.svg"),
        dial_code: "+220",
        id: 80,
        continent: "Africa",
    },
    {
        name: "Georgia",
        code: "ge",
        flag: require("@assets/svg/flags/ge.svg"),
        dial_code: "+995",
        id: 81,
        continent: "Asia",
    },
    {
        name: "Germany",
        code: "de",
        flag: require("@assets/svg/flags/de.svg"),
        dial_code: "+49",
        id: 82,
        continent: "Europe",
    },
    {
        name: "Ghana",
        code: "gh",
        flag: require("@assets/svg/flags/gh.svg"),
        dial_code: "+233",
        id: 83,
        continent: "Africa",
    },
    {
        name: "Gibraltar",
        code: "gi",
        flag: require("@assets/svg/flags/gi.svg"),
        dial_code: "+350",
        id: 84,
        continent: "Europe",
    },
    {
        name: "Greece",
        code: "gr",
        flag: require("@assets/svg/flags/gr.svg"),
        dial_code: "+30",
        id: 85,
        continent: "Europe",
    },
    {
        name: "Greenland",
        code: "gl",
        flag: require("@assets/svg/flags/gl.svg"),
        dial_code: "+299",
        id: 86,
        continent: "North America",
    },
    {
        name: "Grenada",
        code: "gd",
        flag: require("@assets/svg/flags/gd.svg"),
        dial_code: "+1-473",
        id: 87,
        continent: "North America",
    },
    {
        name: "Guadeloupe",
        code: "gp",
        flag: require("@assets/svg/flags/gp.svg"),
        dial_code: "+590",
        id: 88,
        continent: "North America",
    },
    {
        name: "Guam",
        code: "gu",
        flag: require("@assets/svg/flags/gu.svg"),
        dial_code: "+1-671",
        id: 89,
        continent: "Oceania",
    },
    {
        name: "Guatemala",
        code: "gt",
        flag: require("@assets/svg/flags/gt.svg"),
        dial_code: "+502",
        id: 90,
        continent: "North America",
    },
    {
        name: "Guernsey",
        code: "gg",
        flag: require("@assets/svg/flags/gg.svg"),
        dial_code: "+44-1481",
        id: 91,
        continent: "Europe",
    },
    {
        name: "Guinea",
        code: "gn",
        flag: require("@assets/svg/flags/gn.svg"),
        dial_code: "+224",
        id: 92,
        continent: "Africa",
    },
    {
        name: "Guinea-Bissau",
        code: "gw",
        flag: require("@assets/svg/flags/gw.svg"),
        dial_code: "+245",
        id: 93,
        continent: "Africa",
    },
    {
        name: "Guyana",
        code: "gy",
        flag: require("@assets/svg/flags/gy.svg"),
        dial_code: "+592",
        id: 94,
        continent: "South America",
    },
    {
        name: "Haiti",
        code: "ht",
        flag: require("@assets/svg/flags/ht.svg"),
        dial_code: "+509",
        id: 95,
        continent: "North America",
    },
    {
        name: "Heard Island and McDonald Islands",
        code: "hm",
        flag: require("@assets/svg/flags/hm.svg"),
        dial_code: "+672",
        id: 96,
    },
    {
        name: "Honduras",
        code: "hn",
        flag: require("@assets/svg/flags/hn.svg"),
        dial_code: "+504",
        id: 97,
        continent: "North America",
    },
    {
        name: "Hong Kong",
        code: "hk",
        flag: require("@assets/svg/flags/hk.svg"),
        dial_code: "+852",
        id: 98,
        continent: "Asia",
    },
    {
        name: "Hungary",
        code: "hu",
        flag: require("@assets/svg/flags/hu.svg"),
        dial_code: "+36",
        id: 99,
        continent: "Europe",
    },
    {
        name: "Iceland",
        code: "is",
        flag: require("@assets/svg/flags/is.svg"),
        dial_code: "+354",
        id: 100,
        continent: "Europe",
    },
    {
        name: "India",
        code: "in",
        flag: require("@assets/svg/flags/in.svg"),
        dial_code: "+91",
        id: 101,
        continent: "Asia",
    },
    {
        name: "Indonesia",
        code: "id",
        flag: require("@assets/svg/flags/id.svg"),
        dial_code: "+62",
        id: 102,
        continent: "Asia",
    },
    {
        name: "Iran",
        code: "ir",
        flag: require("@assets/svg/flags/ir.svg"),
        dial_code: "+98",
        id: 103,
        continent: "Asia",
    },
    {
        name: "Iraq",
        code: "iq",
        flag: require("@assets/svg/flags/iq.svg"),
        dial_code: "+964",
        id: 104,
        continent: "Asia",
    },
    {
        name: "Ireland",
        code: "ie",
        flag: require("@assets/svg/flags/ie.svg"),
        dial_code: "+353",
        id: 105,
        continent: "Europe",
    },
    {
        name: "Isle of Man",
        code: "im",
        flag: require("@assets/svg/flags/im.svg"),
        dial_code: "+44-1624",
        id: 106,
        continent: "Europe",
    },
    {
        name: "Israel",
        code: "il",
        flag: require("@assets/svg/flags/il.svg"),
        dial_code: "+972",
        id: 107,
        continent: "Asia",
    },
    {
        name: "Italy",
        code: "it",
        flag: require("@assets/svg/flags/it.svg"),
        dial_code: "+39",
        id: 108,
        continent: "Europe",
    },
    {
        name: "Côte d'Ivoire",
        code: "ci",
        flag: require("@assets/svg/flags/ci.svg"),
        dial_code: "+225",
        id: 109,
        continent: "Africa",
    },
    {
        name: "Jamaica",
        code: "jm",
        flag: require("@assets/svg/flags/jm.svg"),
        dial_code: "+1-876",
        id: 110,
        continent: "North America",
    },
    {
        name: "Japan",
        code: "jp",
        flag: require("@assets/svg/flags/jp.svg"),
        dial_code: "+81",
        id: 111,
        continent: "Asia",
    },
    {
        name: "Jersey",
        code: "je",
        flag: require("@assets/svg/flags/je.svg"),
        dial_code: "+44-1534",
        id: 112,
        continent: "Europe",
    },
    {
        name: "Jordan",
        code: "jo",
        flag: require("@assets/svg/flags/jo.svg"),
        dial_code: "+962",
        id: 113,
        continent: "Asia",
    },
    {
        name: "Kazakhstan",
        code: "kz",
        flag: require("@assets/svg/flags/kz.svg"),
        dial_code: "+7",
        id: 114,
        continent: "Asia",
    },
    {
        name: "Kenya",
        code: "ke",
        flag: require("@assets/svg/flags/ke.svg"),
        dial_code: "+254",
        id: 115,
        continent: "Africa",
    },
    {
        name: "Kiribati",
        code: "ki",
        flag: require("@assets/svg/flags/ki.svg"),
        dial_code: "+686",
        id: 116,
        continent: "Oceania",
    },
    {
        name: "Kosovo",
        code: "xk",
        flag: require("@assets/svg/flags/xk.svg"),
        dial_code: "+383",
        id: 117,
        continent: "Europe",
    },
    {
        name: "Kuwait",
        code: "kw",
        flag: require("@assets/svg/flags/kw.svg"),
        dial_code: "+965",
        id: 118,
        continent: "Asia",
    },
    {
        name: "Kyrgyzstan",
        code: "kg",
        flag: require("@assets/svg/flags/kg.svg"),
        dial_code: "+996",
        id: 119,
        continent: "Asia",
    },
    {
        name: "Laos",
        code: "la",
        flag: require("@assets/svg/flags/la.svg"),
        dial_code: "+856",
        id: 120,
        continent: "Asia",
    },
    {
        name: "Latvia",
        code: "lv",
        flag: require("@assets/svg/flags/lv.svg"),
        dial_code: "+371",
        id: 121,
        continent: "Europe",
    },
    {
        name: "Lebanon",
        code: "lb",
        flag: require("@assets/svg/flags/lb.svg"),
        dial_code: "+961",
        id: 122,
        continent: "Asia",
    },
    {
        name: "Lesotho",
        code: "ls",
        flag: require("@assets/svg/flags/ls.svg"),
        dial_code: "+266",
        id: 123,
        continent: "Africa",
    },
    {
        name: "Liberia",
        code: "lr",
        flag: require("@assets/svg/flags/lr.svg"),
        dial_code: "+231",
        id: 124,
        continent: "Africa",
    },
    {
        name: "Libya",
        code: "ly",
        flag: require("@assets/svg/flags/ly.svg"),
        dial_code: "+218",
        id: 125,
        continent: "Africa",
    },
    {
        name: "Liechtenstein",
        code: "li",
        flag: require("@assets/svg/flags/li.svg"),
        dial_code: "+423",
        id: 126,
        continent: "Europe",
    },
    {
        name: "Lithuania",
        code: "lt",
        flag: require("@assets/svg/flags/lt.svg"),
        dial_code: "+370",
        id: 127,
        continent: "Europe",
    },
    {
        name: "Luxembourg",
        code: "lu",
        flag: require("@assets/svg/flags/lu.svg"),
        dial_code: "+352",
        id: 128,
        continent: "Europe",
    },
    {
        name: "Macau",
        code: "mo",
        flag: require("@assets/svg/flags/mo.svg"),
        dial_code: "+853",
        id: 129,
        continent: "Asia",
    },
    {
        name: "North Macedonia",
        code: "mk",
        flag: require("@assets/svg/flags/mk.svg"),
        dial_code: "+389",
        id: 130,
        continent: "Europe",
    },
    {
        name: "Madagascar",
        code: "mg",
        flag: require("@assets/svg/flags/mg.svg"),
        dial_code: "+261",
        id: 131,
        continent: "Africa",
    },
    {
        name: "Malawi",
        code: "mw",
        flag: require("@assets/svg/flags/mw.svg"),
        dial_code: "+265",
        id: 132,
        continent: "Africa",
    },
    {
        name: "Malaysia",
        code: "my",
        flag: require("@assets/svg/flags/my.svg"),
        dial_code: "+60",
        id: 133,
        continent: "Asia",
    },
    {
        name: "Maldives",
        code: "mv",
        flag: require("@assets/svg/flags/mv.svg"),
        dial_code: "+960",
        id: 134,
        continent: "Asia",
    },
    {
        name: "Mali",
        code: "ml",
        flag: require("@assets/svg/flags/ml.svg"),
        dial_code: "+223",
        id: 135,
        continent: "Africa",
    },
    {
        name: "Malta",
        code: "mt",
        flag: require("@assets/svg/flags/mt.svg"),
        dial_code: "+356",
        id: 136,
        continent: "Europe",
    },
    {
        name: "Marshall Islands",
        code: "mh",
        flag: require("@assets/svg/flags/mh.svg"),
        dial_code: "+692",
        id: 137,
        continent: "Oceania",
    },
    {
        name: "Martinique",
        code: "mq",
        flag: require("@assets/svg/flags/mq.svg"),
        dial_code: "+596",
        id: 138,
        continent: "North America",
    },
    {
        name: "Mauritania",
        code: "mr",
        flag: require("@assets/svg/flags/mr.svg"),
        dial_code: "+222",
        id: 139,
        continent: "Africa",
    },
    {
        name: "Mauritius",
        code: "mu",
        flag: require("@assets/svg/flags/mu.svg"),
        dial_code: "+230",
        id: 140,
        continent: "Africa",
    },
    {
        name: "Mayotte",
        code: "yt",
        flag: require("@assets/svg/flags/yt.svg"),
        dial_code: "+262",
        id: 141,
        continent: "Africa",
    },
    {
        name: "Mexico",
        code: "mx",
        flag: require("@assets/svg/flags/mx.svg"),
        dial_code: "+52",
        id: 142,
        continent: "North America",
    },
    {
        name: "Federated States of Micronesia",
        code: "fm",
        flag: require("@assets/svg/flags/fm.svg"),
        dial_code: "+691",
        id: 143,
        continent: "Oceania",
    },
    {
        name: "Moldova",
        code: "md",
        flag: require("@assets/svg/flags/md.svg"),
        dial_code: "+373",
        id: 144,
        continent: "Europe",
    },
    {
        name: "Monaco",
        code: "mc",
        flag: require("@assets/svg/flags/mc.svg"),
        dial_code: "+377",
        id: 145,
        continent: "Europe",
    },
    {
        name: "Mongolia",
        code: "mn",
        flag: require("@assets/svg/flags/mn.svg"),
        dial_code: "+976",
        id: 146,
        continent: "Asia",
    },
    {
        name: "Montenegro",
        code: "me",
        flag: require("@assets/svg/flags/me.svg"),
        dial_code: "+382",
        id: 147,
        continent: "Europe",
    },
    {
        name: "Montserrat",
        code: "ms",
        flag: require("@assets/svg/flags/ms.svg"),
        dial_code: "+1-664",
        id: 148,
        continent: "North America",
    },
    {
        name: "Morocco",
        code: "ma",
        flag: require("@assets/svg/flags/ma.svg"),
        dial_code: "+212",
        id: 149,
        continent: "Africa",
    },
    {
        name: "Mozambique",
        code: "mz",
        flag: require("@assets/svg/flags/mz.svg"),
        dial_code: "+258",
        id: 150,
        continent: "Africa",
    },
    {
        name: "Myanmar",
        code: "mm",
        flag: require("@assets/svg/flags/mm.svg"),
        dial_code: "+95",
        id: 151,
        continent: "Asia",
    },
    {
        name: "Namibia",
        code: "na",
        flag: require("@assets/svg/flags/na.svg"),
        dial_code: "+264",
        id: 152,
        continent: "Africa",
    },
    {
        name: "Nauru",
        code: "nr",
        flag: require("@assets/svg/flags/nr.svg"),
        dial_code: "+674",
        id: 153,
        continent: "Oceania",
    },
    {
        name: "Nepal",
        code: "np",
        flag: require("@assets/svg/flags/np.svg"),
        dial_code: "+977",
        id: 154,
        continent: "Asia",
    },
    {
        name: "Netherlands",
        code: "nl",
        flag: require("@assets/svg/flags/nl.svg"),
        dial_code: "+31",
        id: 155,
        continent: "Europe",
    },
    {
        name: "New Caledonia",
        code: "nc",
        flag: require("@assets/svg/flags/nc.svg"),
        dial_code: "+687",
        id: 156,
        continent: "Oceania",
    },
    {
        name: "New Zealand",
        code: "nz",
        flag: require("@assets/svg/flags/nz.svg"),
        dial_code: "+64",
        id: 157,
        continent: "Oceania",
    },
    {
        name: "Nicaragua",
        code: "ni",
        flag: require("@assets/svg/flags/ni.svg"),
        dial_code: "+505",
        id: 158,
        continent: "North America",
    },
    {
        name: "Niger",
        code: "ne",
        flag: require("@assets/svg/flags/ne.svg"),
        dial_code: "+227",
        id: 159,
        continent: "Africa",
    },
    {
        name: "Nigeria",
        code: "ng",
        flag: require("@assets/svg/flags/ng.svg"),
        dial_code: "+234",
        id: 160,
        continent: "Africa",
    },
    {
        name: "Niue",
        code: "nu",
        flag: require("@assets/svg/flags/nu.svg"),
        dial_code: "+683",
        id: 161,
        continent: "Oceania",
    },
    {
        name: "Norfolk Island",
        code: "nf",
        flag: require("@assets/svg/flags/nf.svg"),
        dial_code: "+672",
        id: 162,
        continent: "Oceania",
    },
    {
        name: "North Korea",
        code: "kp",
        flag: require("@assets/svg/flags/kp.svg"),
        dial_code: "+850",
        id: 163,
        continent: "Asia",
    },
    {
        name: "Northern Mariana Islands",
        code: "mp",
        flag: require("@assets/svg/flags/mp.svg"),
        dial_code: "+1-670",
        id: 164,
        continent: "Oceania",
    },
    {
        name: "Norway",
        code: "no",
        flag: require("@assets/svg/flags/no.svg"),
        dial_code: "+47",
        id: 165,
        continent: "Europe",
    },
    {
        name: "Oman",
        code: "om",
        flag: require("@assets/svg/flags/om.svg"),
        dial_code: "+968",
        id: 166,
        continent: "Asia",
    },
    {
        name: "Pakistan",
        code: "pk",
        flag: require("@assets/svg/flags/pk.svg"),
        dial_code: "+92",
        id: 167,
        continent: "Asia",
    },
    {
        name: "Palau",
        code: "pw",
        flag: require("@assets/svg/flags/pw.svg"),
        dial_code: "+680",
        id: 168,
        continent: "Oceania",
    },
    {
        name: "State of Palestine",
        code: "ps",
        flag: require("@assets/svg/flags/ps.svg"),
        dial_code: "+970",
        id: 169,
        continent: "Asia",
    },
    {
        name: "Panama",
        code: "pa",
        flag: require("@assets/svg/flags/pa.svg"),
        dial_code: "+507",
        id: 170,
        continent: "North America",
    },
    {
        name: "Papua New Guinea",
        code: "pg",
        flag: require("@assets/svg/flags/pg.svg"),
        dial_code: "+675",
        id: 171,
        continent: "Oceania",
    },
    {
        name: "Paraguay",
        code: "py",
        flag: require("@assets/svg/flags/py.svg"),
        dial_code: "+595",
        id: 172,
        continent: "South America",
    },
    {
        name: "Peru",
        code: "pe",
        flag: require("@assets/svg/flags/pe.svg"),
        dial_code: "+51",
        id: 173,
        continent: "South America",
    },
    {
        name: "Philippines",
        code: "ph",
        flag: require("@assets/svg/flags/ph.svg"),
        dial_code: "+63",
        id: 174,
        continent: "Asia",
    },
    {
        name: "Pitcairn",
        code: "pn",
        flag: require("@assets/svg/flags/pn.svg"),
        dial_code: "+870",
        id: 175,
        continent: "Oceania",
    },
    {
        name: "Poland",
        code: "pl",
        flag: require("@assets/svg/flags/pl.svg"),
        dial_code: "+48",
        id: 176,
        continent: "Europe",
    },
    {
        name: "Portugal",
        code: "pt",
        flag: require("@assets/svg/flags/pt.svg"),
        dial_code: "+351",
        id: 177,
        continent: "Europe",
    },
    {
        name: "Puerto Rico",
        code: "pr",
        flag: require("@assets/svg/flags/pr.svg"),
        dial_code: "+1-787",
        id: 178,
        continent: "North America",
    },
    {
        name: "Qatar",
        code: "qa",
        flag: require("@assets/svg/flags/qa.svg"),
        dial_code: "+974",
        id: 179,
        continent: "Asia",
    },
    {
        name: "Republic of the Congo",
        code: "cg",
        flag: require("@assets/svg/flags/cg.svg"),
        dial_code: "+242",
        id: 180,
        continent: "Africa",
    },
    {
        name: "Réunion",
        code: "re",
        flag: require("@assets/svg/flags/re.svg"),
        dial_code: "+262",
        id: 181,
        continent: "Africa",
    },
    {
        name: "Romania",
        code: "ro",
        flag: require("@assets/svg/flags/ro.svg"),
        dial_code: "+40",
        id: 182,
        continent: "Europe",
    },
    {
        name: "Russia",
        code: "ru",
        flag: require("@assets/svg/flags/ru.svg"),
        dial_code: "+7",
        id: 183,
        continent: "Europe",
    },
    {
        name: "Rwanda",
        code: "rw",
        flag: require("@assets/svg/flags/rw.svg"),
        dial_code: "+250",
        id: 184,
        continent: "Africa",
    },
    {
        name: "Saint Barthélemy",
        code: "bl",
        flag: require("@assets/svg/flags/bl.svg"),
        dial_code: "+590",
        id: 185,
        continent: "North America",
    },
    {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        code: "sh",
        flag: require("@assets/svg/flags/sh.svg"),
        dial_code: "+290",
        id: 186,
        continent: "Africa",
    },
    {
        name: "Saint Kitts and Nevis",
        code: "kn",
        flag: require("@assets/svg/flags/kn.svg"),
        dial_code: "+1-869",
        id: 187,
        continent: "North America",
    },
    {
        name: "Saint Lucia",
        code: "lc",
        flag: require("@assets/svg/flags/lc.svg"),
        dial_code: "+1-758",
        id: 188,
        continent: "North America",
    },
    {
        name: "Saint Martin",
        code: "mf",
        flag: require("@assets/svg/flags/mf.svg"),
        dial_code: "+590",
        id: 189,
        continent: "North America",
    },
    {
        name: "Saint Pierre and Miquelon",
        code: "pm",
        flag: require("@assets/svg/flags/pm.svg"),
        dial_code: "+508",
        id: 190,
        continent: "North America",
    },
    {
        name: "Saint Vincent and the Grenadines",
        code: "vc",
        flag: require("@assets/svg/flags/vc.svg"),
        dial_code: "+1-784",
        id: 191,
        continent: "North America",
    },
    {
        name: "Samoa",
        code: "ws",
        flag: require("@assets/svg/flags/ws.svg"),
        dial_code: "+685",
        id: 192,
        continent: "Oceania",
    },
    {
        name: "San Marino",
        code: "sm",
        flag: require("@assets/svg/flags/sm.svg"),
        dial_code: "+378",
        id: 193,
        continent: "Europe",
    },
    {
        name: "Sao Tome and Principe",
        code: "st",
        flag: require("@assets/svg/flags/st.svg"),
        dial_code: "+239",
        id: 194,
        continent: "Africa",
    },
    {
        name: "Saudi Arabia",
        code: "sa",
        flag: require("@assets/svg/flags/sa.svg"),
        dial_code: "+966",
        id: 195,
        continent: "Asia",
    },
    {
        name: "Senegal",
        code: "sn",
        flag: require("@assets/svg/flags/sn.svg"),
        dial_code: "+221",
        id: 196,
        continent: "Africa",
    },
    {
        name: "Serbia",
        code: "rs",
        flag: require("@assets/svg/flags/rs.svg"),
        dial_code: "+381",
        id: 197,
        continent: "Europe",
    },
    {
        name: "Seychelles",
        code: "sc",
        flag: require("@assets/svg/flags/sc.svg"),
        dial_code: "+248",
        id: 198,
        continent: "Africa",
    },
    {
        name: "Sierra Leone",
        code: "sl",
        flag: require("@assets/svg/flags/sl.svg"),
        dial_code: "+232",
        id: 199,
        continent: "Africa",
    },
    {
        name: "Singapore",
        code: "sg",
        flag: require("@assets/svg/flags/sg.svg"),
        dial_code: "+65",
        id: 200,
        continent: "Asia",
    },
    {
        name: "Sint Maarten",
        code: "sx",
        flag: require("@assets/svg/flags/sx.svg"),
        dial_code: "+599",
        id: 201,
        continent: "North America",
    },
    {
        name: "Slovakia",
        code: "sk",
        flag: require("@assets/svg/flags/sk.svg"),
        dial_code: "+421",
        id: 202,
        continent: "Europe",
    },
    {
        name: "Slovenia",
        code: "si",
        flag: require("@assets/svg/flags/si.svg"),
        dial_code: "+386",
        id: 203,
        continent: "Europe",
    },
    {
        name: "Solomon Islands",
        code: "sb",
        flag: require("@assets/svg/flags/sb.svg"),
        dial_code: "+677",
        id: 204,
        continent: "Oceania",
    },
    {
        name: "Somalia",
        code: "so",
        flag: require("@assets/svg/flags/so.svg"),
        dial_code: "+252",
        id: 205,
        continent: "Africa",
    },
    {
        name: "South Africa",
        code: "za",
        flag: require("@assets/svg/flags/za.svg"),
        dial_code: "+27",
        id: 206,
        continent: "Africa",
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        code: "gs",
        flag: require("@assets/svg/flags/gs.svg"),
        dial_code: "+500",
        id: 207,
        continent: "Antarctica",
    },
    {
        name: "South Korea",
        code: "kr",
        flag: require("@assets/svg/flags/kr.svg"),
        dial_code: "+82",
        id: 208,
        continent: "Asia",
    },
    {
        name: "South Sudan",
        code: "ss",
        flag: require("@assets/svg/flags/ss.svg"),
        dial_code: "+211",
        id: 209,
        continent: "Africa",
    },
    {
        name: "Spain",
        code: "es",
        flag: require("@assets/svg/flags/es.svg"),
        dial_code: "+34",
        id: 210,
        continent: "Europe",
    },
    {
        name: "Sri Lanka",
        code: "lk",
        flag: require("@assets/svg/flags/lk.svg"),
        dial_code: "+94",
        id: 211,
        continent: "Asia",
    },
    {
        name: "Sudan",
        code: "sd",
        flag: require("@assets/svg/flags/sd.svg"),
        dial_code: "+249",
        id: 212,
        continent: "Africa",
    },
    {
        name: "Suriname",
        code: "sr",
        flag: require("@assets/svg/flags/sr.svg"),
        dial_code: "+597",
        id: 213,
        continent: "South America",
    },
    {
        name: "Svalbard and Jan Mayen",
        code: "sj",
        flag: require("@assets/svg/flags/sj.svg"),
        dial_code: "+47",
        id: 214,
        continent: "Europe",
    },
    {
        name: "Eswatini",
        code: "sz",
        flag: require("@assets/svg/flags/sz.svg"),
        dial_code: "+268",
        id: 215,
        continent: "Africa",
    },
    {
        name: "Sweden",
        code: "se",
        flag: require("@assets/svg/flags/se.svg"),
        dial_code: "+46",
        id: 216,
        continent: "Europe",
    },
    {
        name: "Switzerland",
        code: "ch",
        flag: require("@assets/svg/flags/ch.svg"),
        dial_code: "+41",
        id: 217,
        continent: "Europe",
    },
    {
        name: "Syria",
        code: "sy",
        flag: require("@assets/svg/flags/sy.svg"),
        dial_code: "+963",
        id: 218,
        continent: "Asia",
    },
    {
        name: "Taiwan",
        code: "tw",
        flag: require("@assets/svg/flags/tw.svg"),
        dial_code: "+886",
        id: 219,
        continent: "Asia",
    },
    {
        name: "Tajikistan",
        code: "tj",
        flag: require("@assets/svg/flags/tj.svg"),
        dial_code: "+992",
        id: 220,
        continent: "Asia",
    },
    {
        name: "Tanzania",
        code: "tz",
        flag: require("@assets/svg/flags/tz.svg"),
        dial_code: "+255",
        id: 221,
        continent: "Africa",
    },
    {
        name: "Thailand",
        code: "th",
        flag: require("@assets/svg/flags/th.svg"),
        dial_code: "+66",
        id: 222,
        continent: "Asia",
    },
    {
        name: "Togo",
        code: "tg",
        flag: require("@assets/svg/flags/tg.svg"),
        dial_code: "+228",
        id: 223,
        continent: "Africa",
    },
    {
        name: "Tokelau",
        code: "tk",
        flag: require("@assets/svg/flags/tk.svg"),
        dial_code: "+690",
        id: 224,
        continent: "Oceania",
    },
    {
        name: "Tonga",
        code: "to",
        flag: require("@assets/svg/flags/to.svg"),
        dial_code: "+676",
        id: 225,
        continent: "Oceania",
    },
    {
        name: "Trinidad and Tobago",
        code: "tt",
        flag: require("@assets/svg/flags/tt.svg"),
        dial_code: "+1-868",
        id: 226,
        continent: "South America",
    },
    {
        name: "Tunisia",
        code: "tn",
        flag: require("@assets/svg/flags/tn.svg"),
        dial_code: "+216",
        id: 227,
        continent: "Africa",
    },
    {
        name: "Turkey",
        code: "tr",
        flag: require("@assets/svg/flags/tr.svg"),
        dial_code: "+90",
        id: 228,
        continent: "Asia",
    },
    {
        name: "Turkmenistan",
        code: "tm",
        flag: require("@assets/svg/flags/tm.svg"),
        dial_code: "+993",
        id: 229,
        continent: "Asia",
    },
    {
        name: "Turks and Caicos Islands",
        code: "tc",
        flag: require("@assets/svg/flags/tc.svg"),
        dial_code: "+1-649",
        id: 230,
        continent: "North America",
    },
    {
        name: "Tuvalu",
        code: "tv",
        flag: require("@assets/svg/flags/tv.svg"),
        dial_code: "+688",
        id: 231,
        continent: "Oceania",
    },
    {
        name: "Virgin Islands (U.S.)",
        code: "vi",
        flag: require("@assets/svg/flags/vi.svg"),
        dial_code: "+1-340",
        id: 232,
        continent: "North America",
    },
    {
        name: "Uganda",
        code: "ug",
        flag: require("@assets/svg/flags/ug.svg"),
        dial_code: "+256",
        id: 233,
        continent: "Africa",
    },
    {
        name: "Ukraine",
        code: "ua",
        flag: require("@assets/svg/flags/ua.svg"),
        dial_code: "+380",
        id: 234,
        continent: "Europe",
    },
    {
        name: "United Arab Emirates",
        code: "ae",
        flag: require("@assets/svg/flags/ae.svg"),
        dial_code: "+971",
        id: 235,
        continent: "Asia",
    },
    {
        name: "United Kingdom",
        code: "gb",
        flag: require("@assets/svg/flags/gb.svg"),
        dial_code: "+44",
        id: 236,
        continent: "Europe",
    },
    {
        name: "United States Minor Outlying Islands",
        code: "um",
        flag: require("@assets/svg/flags/um.svg"),
        dial_code: "+1",
        id: 237,
        continent: "North America",
    },
    {
        name: "United States of America",
        code: "us",
        flag: require("@assets/svg/flags/us.svg"),
        dial_code: "+1",
        id: 238,
        continent: "North America",
    },
    {
        name: "Uruguay",
        code: "uy",
        flag: require("@assets/svg/flags/uy.svg"),
        dial_code: "+598",
        id: 239,
        continent: "South America",
    },
    {
        name: "Uzbekistan",
        code: "uz",
        flag: require("@assets/svg/flags/uz.svg"),
        dial_code: "+998",
        id: 240,
        continent: "Asia",
    },
    {
        name: "Vanuatu",
        code: "vu",
        flag: require("@assets/svg/flags/vu.svg"),
        dial_code: "+678",
        id: 241,
        continent: "Oceania",
    },
    {
        name: "Holy See",
        code: "va",
        flag: require("@assets/svg/flags/va.svg"),
        dial_code: "+379",
        id: 242,
        continent: "Europe",
    },
    {
        name: "Venezuela",
        code: "ve",
        flag: require("@assets/svg/flags/ve.svg"),
        dial_code: "+58",
        id: 243,
        continent: "South America",
    },
    {
        name: "Vietnam",
        code: "vn",
        flag: require("@assets/svg/flags/vn.svg"),
        dial_code: "+84",
        id: 244,
        continent: "Asia",
    },
    {
        name: "Wallis and Futuna",
        code: "wf",
        flag: require("@assets/svg/flags/wf.svg"),
        dial_code: "+681",
        id: 245,
        continent: "Oceania",
    },
    {
        name: "Western Sahara",
        code: "eh",
        flag: require("@assets/svg/flags/eh.svg"),
        dial_code: "+212",
        id: 246,
        continent: "Africa",
    },
    {
        name: "Yemen",
        code: "ye",
        flag: require("@assets/svg/flags/ye.svg"),
        dial_code: "+967",
        id: 247,
        continent: "Asia",
    },
    {
        name: "Zambia",
        code: "zm",
        flag: require("@assets/svg/flags/zm.svg"),
        dial_code: "+260",
        id: 248,
        continent: "Africa",
    },
    {
        name: "Zimbabwe",
        code: "zw",
        flag: require("@assets/svg/flags/zw.svg"),
        dial_code: "+263",
        id: 249,
        continent: "Africa",
    },
];
