import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import { setupCalendar } from "v-calendar";

import Vuelidate from "vuelidate";
import { BootstrapVue } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(VueObserveVisibility);
Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VCalendar, {
    componentPrefix: "vc",
});

setupCalendar({
    componentPrefix: "vc",
});

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/scss/app.scss";

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
