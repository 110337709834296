// get access token
export function getAccessToken() {
    const token = localStorage.getItem("access_token");

    if (token) return `Bearer ${token}`;
    else return false;
}
// check if user logged in
export function checkUserLoggedIn() {
    return getAccessToken() ? true : false;
}

export function checkCanNavigateWithoutToken(to) {
    return to.meta.canNavigateWithoutToken
}

export function getUserInfo() {
    if (checkUserLoggedIn()) {
        // console.log(localStorage.getItem(unfi));
    }
}