export default [
    {
        path: "/",
        name: "queues-index",
        component: () => import("@views/queues/Index.vue"),
        meta: {
            layout: "expanded",
            identifier: "queues",
            header: {
                title: "Queue List",
                subtitle: "Manage Your Queue List",
            },
            canNavigateWithoutToken: false,
        },
    },
];
