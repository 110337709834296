import Vue from "vue";
import Vuex from "vuex";

// Modules
import auth from "./auth";
import jobs from "./jobs";
import zoom from "./app/zoom";
import tasks from "./tasks";
import users from "./users";
import queues from "./queues";
import image from "./app/image";
import archives from "./archives";
import project from "./app/project";
import countries from "./app/countries";
import mask from "./item-removal/mask";
import versionHistory from "./app/version-history";
import itemRemoval from "./item-removal/index";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        jobs,
        zoom,
        auth,
        image,
        tasks,
        users,
        queues,
        project,
        archives,
        countries,
        itemRemoval,
        mask,
        versionHistory,
    },
    strict: process.env.DEV,
});
