import _ from "lodash";

export default {
    namespace: true,
    state: {
        jobs: [],
        job: undefined,
    },
    getters: {
        getJobs() {},
        getJob: ({ job }) => job,
    },
    mutations: {
        SET_JOB(state, payload) {
            state.job = payload;
        },
    },
    actions: {
        async fetchJob({ commit }, data) {
            let response;

            commit("SET_JOB", data);

            try {
                response = await new Promise((resolve, reject) => {
                    resolve(data);
                    reject();
                });
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
    },
};
