import _ from "lodash";
import { getOperators } from "@/api/users";

export default {
    namespace: true,
    state: {
        operators: undefined,
    },
    getters: {
        getOperators: ({ operators }) => operators,
    },
    mutations: {
        SET_OPERATORS(state, payload) {
          state.operators = payload;
        },
    },
    actions: {
        async fetchOperators({ commit }, data) {
            let response;

            try {
                response = getOperators(data);
            } catch (error) {
                throw new Error(error);
            }

            response
                .then(({ data }) => {
                    commit("SET_OPERATORS", data);
                })
                .catch((error) => {
                    console.log(error);
                });

            return response;
        },
    },
};
