export default [
    {
        path: "/jobs",
        name: "jobs-list",
        component: () => import("@views/jobs/Index.vue"),
        meta: {
            layout: "expanded",
            identifier: 'jobs',
            header: {
                title: "Jobs List",
                subtitle: "Confirm or Reject Current Jobs",
            },
        },
    },
    {
        path: "/jobs/:id",
        name: "jobs-detail",
        component: () => import("@views/jobs/Output.vue"),
        meta: {
            layout: "output",
            identifier: 'jobs',
            header: {
                title: "Jobs ",
                subtitle: "Confirm or Reject Current Jobs",
            },
        },
    },
    {
        path: "/jobs/:id/reject",
        name: "jobs-reject",
        component: () => import("@views/jobs/Reject.vue"),
        meta: {
            layout: "output",
            identifier: 'jobs',
            header: {
                plane: true,
                title: "Jobs ",
                subtitle: "Confirm or Reject Current Jobs",
            },
        },
    },
];
