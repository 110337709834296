export default {
    namespace: true,
    state: {
        brushWidth: 10,
        eraserWidth: 10,
        brushType: "mask",
        isCanvasEmpty: true,
        maskProcessState: false,
    },
    getters: {
        getBrushType: ({ brushType }) => brushType,
        getBrushWidth: ({ brushWidth }) => brushWidth,
        getEraserWidth: ({ eraserWidth }) => eraserWidth,
        getIsCanvasEmpty: ({ isCanvasEmpty }) => isCanvasEmpty,
        getMaskProcessState: ({ maskProcessState }) => maskProcessState,
    },
    mutations: {
        setBrushType: (state, type) => (state.brushType = type),
        setBrushWidth: (state, width) => (state.brushWidth = width),
        setEraserWidth: (state, width) => (state.eraserWidth = width),
        setIsCanvasEmpty: (state, isEmpty) => (state.isCanvasEmpty = isEmpty),
        setMaskProcessState: (state, processState) => (state.maskProcessState = processState),
    },
    actions: {
        async sendMask(data) {
            return await api.sendMask(data);
        },
        async changeMaskProcessState({ commit }, processState) {
            return await new Promise((resolve, reject) => {
                commit("setMaskProcessState", processState);
                resolve(processState);
                reject();
            });
        },
    },
};
