import _ from "lodash";
import { logout, login } from "@/api/auth/index";

export default {
    namespace: true,
    state: {
    },
    getters: {
        getUserFullName() {
            let firstName, lastName;

            const userInfo = JSON.parse(localStorage.getItem('userInfo'));

            if (userInfo) {
                lastName = userInfo.lastName
                firstName = userInfo.firstName
                return `${firstName} ${lastName}`;
            } else {
                return '';
            }

        },
    },
    mutations: {
        SET_USER_INFO(state, payload) {
            const userInfo = _.omit(payload, ["access_token"]);

            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            localStorage.setItem("access_token", payload.access_token);
        },
        REMOVE_USER_INFO(state) {
            localStorage.removeItem('userInfo')
            localStorage.removeItem('access_token')
        },
    },
    actions: {
        async fetchUserInfo({ commit }, data) {
            let response;

            try {
                response = await login(data)
                    .then((response) => {
                        commit("SET_USER_INFO", response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
        async logoutUser({commit}) {
            let response;

            try {
                response = await logout()
                    .then(() => {
                        commit('REMOVE_USER_INFO');
                    })
                    .catch(() => {})
            } catch (error) {
                throw new Error(error)
            }

            return response;
        },
    },
};
