import _ from "lodash";

export default {
    namespace: true,
    state: {
        archives: [],
        archive: undefined,
    },
    getters: {
        getArchives() {},
        getArchive: ({ archive }) => archive,
    },
    mutations: {
        SET_ARCHIVE(state, payload) {
            state.archive = payload;
        },
    },
    actions: {
        async fetchArchive({ commit }, data) {
            let response;

            commit("SET_ARCHIVE", data);

            try {
                response = await new Promise((resolve, reject) => {
                    resolve(data);
                    reject();
                });
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
    },
};
