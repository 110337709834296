import _ from "lodash";

export default {
    namespace: true,
    state: {
        focusMode: false,
        cursor: undefined,
        originalPhotoSwitchState: false,
        histories: [],
        activeHistoryIndex: 0,
    },
    getters: {
        getCursor: ({ cursor }) => cursor,
        getFocusMode: ({ focusMode }) => focusMode,
        getOriginalPhotoSwitchState: ({ originalPhotoSwitchState }) => originalPhotoSwitchState,
        getHistories: ({ histories }) => histories,
        getOriginalPhoto: ({ histories }) => _.first(histories),
        getProcessingPhoto: ({ histories, activeHistoryIndex }) => histories[activeHistoryIndex],
        getActiveHistoryIndex: ({ activeHistoryIndex }) => activeHistoryIndex,
    },
    mutations: {
        setCursor: (state, cursor) => (state.cursor = cursor),
        setFocusMode: (state, payload) => (state.focusMode = payload),
        setOriginalPhotoSwitchState: (state, payload) => (state.originalPhotoSwitchState = payload),
        addRevision: (state, revision) => state.histories.push(revision),
        setActiveHistoryIndex: (state, index) => (state.activeHistoryIndex = index),
    },
    actions: {
        async changeFocusMode({ commit }, focusMode) {
            return await new Promise((resolve, reject) => {
                commit("setFocusMode", focusMode);
                resolve(focusMode);
                reject();
            });
        },
        async changeOriginalPhotoSwitchState({ commit }, originalPhotoSwitchState) {
            return await new Promise((resolve, reject) => {
                commit("setOriginalPhotoSwitchState", originalPhotoSwitchState);
                resolve(originalPhotoSwitchState);
                reject();
            });
        },
        async addRevisionToHistory({ commit }, revision) {
            return await new Promise((resolve, reject) => {
                commit("addRevision", revision);
                resolve(revision);
                reject();
            });
        },
        async changeActiveHistoryIndex({ commit }, index) {
            return await new Promise((resolve, reject) => {
                commit("setActiveHistoryIndex", index);
                resolve(index);
                reject();
            });
        },
    },
};
