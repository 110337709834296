export default {
    namespace: true,
    state: {
        photoId: "AS56dD",
        outputId: "912732",
        projectId: "010233",
        dynamicPageTitle: undefined,
    },
    getters: {
        getPhotoId: ({ photoId }) => photoId,
        getOutputId: ({ outputId }) => outputId,
        getProjectId: ({ projectId }) => projectId,
        getDynamicPageTitle: ({ dynamicPageTitle }) => dynamicPageTitle,
    },
    mutations: {
        setPhotoId: (state, id) => (state.photoId = id),
        setOutputId: (state, id) => (state.outputId = id),
        setProjectId: (state, id) => (state.projectId = id),
        setDynamicPageTitle: (state, title) => (state.dynamicPageTitle = title),
    },
    actions: {
        async fetchPhotoId({ commit }, id) {
            return await new Promise((resolve, reject) => {
                commit("setPhotoId", id);
                resolve();
                reject();
            });
        },
        async fetchOutputId({ commit }, id) {
            return await new Promise((resolve, reject) => {
                commit("setOutputId", id);
                resolve();
                reject();
            });
        },
        async fetchProjectId({ commit }, id) {
            return await new Promise((resolve, reject) => {
                commit("setProjectId", id);
                resolve();
                reject();
            });
        },
        async fetchDynamicPageTitle({ commit }, title) {
            return await new Promise((resolve, reject) => {
                commit("setDynamicPageTitle", title);
                resolve();
                reject();
            });
        },
    },
};
