export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@views/auth/Login.vue"),
        meta: {
            layout: "full",
            canNavigateWithoutToken: true,
        },
    },
    {
        path: "/error-404",
        name: "error-404",
        component: () => import("@views/errors/Error404.vue"),
        meta: {
            canNavigateWithoutToken: true,
        },
    },
];
