import _ from "lodash";

export default {
    namespace: true,
    state: {
        hasVersionHistory: true,
    },
    getters: {
        getHasVersionHistory: ({ hasVersionHistory }) => hasVersionHistory,
    },
    mutations: {
        setHasVersionHistory: (state, hasVersionHistory) => (state.hasVersionHistory = hasVersionHistory),
    },
    actions: {},
};
