import axiosIns from "@/plugins/axios";

export async function getQueuesStats() {
    return await axiosIns({
        method: "get",
        url: "/admin/task/unassigned-tasks/times-report",
    });
}

export async function getUnassignedQueues() {
    return await axiosIns({
        method: "get",
        url: "/admin/task/unassigned-tasks",
    });
}

export async function getAssignedQueues() {
    return await axiosIns({
        method: "get",
        url: "/admin/task/assigned-tasks",
    });
}