import axios from "@/plugins/axios";

export async function login(data) {
    return await axios({
        method: "post",
        url: "/login",
        data,
    });
}

export async function logout() {
    return await axios({
        method: "post",
        url: "/logout"
    });
}