export default [
    {
        path: "/archives",
        name: "archives-list",
        component: () => import("@views/archives/Index.vue"),
        meta: {
            layout: "expanded",
            identifier: 'archives',
            header: {
                title: "Archive List",
                subtitle: "View and Manage Previous Services",
            },
        },
    },
    {
        path: "/archives/:id",
        name: "archives-detail",
        component: () => import("@views/archives/Detail.vue"),
        meta: {
            layout: "detail",
            identifier: 'archives',
            header: {
                hash: true,
                title: "Detail",
                subtitle: "Review the Service",
            },
        },
    },
];
