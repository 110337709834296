const name = localStorage.getItem("user") || "Mahdi";

export default [
    {
        path: "/account",
        name: "account",
        component: () => import("@views/account/Index.vue"),
        meta: {
            layout: "expanded",
            header: {
                title: "My Account",
                subtitle: `Welcome ${name}!`,
            },
            canNavigateWithoutToken: false,
        },
    },
];
