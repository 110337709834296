import _ from "lodash";
import { previewTask } from "@/api/tasks";

export default {
    namespace: true,
    state: {
        previewTask: undefined,
    },
    getters: {
        getPreviewTask: ({ previewTask }) => previewTask,
    },
    mutations: {
        SET_PREVIEW_TASK(state, payload) {
            state.previewTask = payload;
        },
    },
    actions: {
        async fetchPreviewTask({ commit }, data) {
            let response;

            try {
                response = previewTask(data);
            } catch (error) {
                throw new Error(error);
            }

            response
                .then(({ data }) => {
                    commit("SET_PREVIEW_TASK", data);
                })
                .catch((error) => {
                    console.log(error);
                });

            return response;
        },
    },
};
